import { template as template_0f10199f51c0496698d4238d808e06eb } from "@ember/template-compiler";
const FKControlMenuContainer = template_0f10199f51c0496698d4238d808e06eb(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
