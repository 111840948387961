import { template as template_32c35d91655b4718a81a9be27c46f063 } from "@ember/template-compiler";
const FKLabel = template_32c35d91655b4718a81a9be27c46f063(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
