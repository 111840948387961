import { template as template_142e695351f244a49aebc719cbc03e97 } from "@ember/template-compiler";
const FKText = template_142e695351f244a49aebc719cbc03e97(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
