import { template as template_afb8c6eda19e46a7a583705a0c7a845a } from "@ember/template-compiler";
const WelcomeHeader = template_afb8c6eda19e46a7a583705a0c7a845a(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
