import { template as template_411fc55b1d5946a4bf43d7122b57b4d5 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_411fc55b1d5946a4bf43d7122b57b4d5(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
